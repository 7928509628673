.card {
  width: 300px;
  height: 170px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.card-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-title {
  font-size: 0.8rem;
  margin: 0;
  font-family: "Tajawal", sans-serif;
}

.card-description {
  font-size: 14px;
  margin: 10px 0;
  font-family: "Tajawal", sans-serif;
}

.card-count {
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  text-align: right;
  align-self: flex-end;
  font-family: "Tajawal", sans-serif;
}
