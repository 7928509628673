/* تصحيح التخطيط العام */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
}

/* تكبير الكارت وجعله مقسمًا إلى قسمين */
/* تكبير الكارت وجعله مقسمًا إلى قسمين */
.login-card {
  display: flex;
  flex-direction: row-reverse; /* عكس الاتجاه ليكون القسم الأيمن للصورة */
  width: 800px;
  height: 650px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

/* القسم الأول: صورة */
.login-image-section {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ddd;
}

.login-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* القسم الثاني: نموذج تسجيل الدخول */
/* تنسيق القسم الثاني ليكون متناسقًا */

.login-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Tajawal", sans-serif;
}

.error-message {
  color: red;
  text-align: center;
  font-family: "Tajawal", sans-serif;
}
/* ضبط الحاوية العامة */
.login-form-section {
  width: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch; /* جعل جميع العناصر تمتد بعرض الحاوية */
}

/* تنسيق الحقول بحيث يكون كل من input و button بنفس الحجم */
.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-label {
  font-family: "Tajawal", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: right;
  margin-top: 5px;
  direction: rtl;
  width: 100%;
}

/* ضمان أن input و button بنفس الحجم */
.input-field,
.submit-button {
  font-family: "Tajawal", sans-serif;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  text-align: right;
  direction: rtl;
  box-sizing: border-box; /* يجعل الحجم يشمل البوردر والـ padding */
}

.submit-button {
  margin-top: 10px;
  background: #0a0b0c;
  color: white;
  font-size: 16px;
  text-align: center;

  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.submit-button:hover {
  background: #0056b3;
}
