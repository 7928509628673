/* ✅ ضبط كارت معلومات المستخدم */
.user-info-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  position: relative; /* لجعل أيقونة الرجوع داخل الكارت */
}

/* ✅ أيقونة الرجوع داخل الكارت في الزاوية العلوية اليسرى */
.back-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.back-icon:hover {
  color: #0056b3;
}

/* ✅ ضبط الحاوية بحيث يكون العناصر بجانب بعضها */
.user-info {
  display: flex;
  align-items: center;
  gap: 20px; /* المسافة بين الصورة والمعلومات */
  width: 100%;
  padding-top: 10px; /* لضمان عدم تداخل الأيقونة مع المعلومات */
}

/* ✅ ضبط حجم الصورة وتنسيقها */
.user-image-container {
  flex-shrink: 0; /* يمنع الصورة من التمدد */
}

.user-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #ddd;
}

/* ✅ ضبط النصوص على اليمين */
.user-details {
  flex-grow: 1; /* يأخذ المساحة المتبقية */
  display: flex;
  flex-direction: column;
}

.user-details h2 {
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: bold;
  color: #333;
}

.user-details p {
  margin: 4px 0;
  font-size: 16px;
  color: #555;
}

.user-details strong {
  color: #222;
}
