.product-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 20px;
}
th,
td {
  font-family: "Tajawal", sans-serif;
  font-size: 12px;
  align-items: center;
  align-content: center;
  align-self: center;
}
.header-container {
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 20px;
}
.color-table {
  background-color: linear-gradient(
    to top,
    rgb(25 113 132),
    rgb(38 155 180)
  ) !important;
}

.add-button {
  flex: 0 1 25%; /* تحديد عرض الزر ليكون 25% */
  padding: 10px;
  font-size: 16px;
  background: linear-gradient(to top, rgb(25, 113, 132), rgb(38, 155, 180));
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Tajawal", sans-serif;
  text-align: center;
}

.add-button:hover {
  background: linear-gradient(
    to top,
    rgb(20, 90, 110),
    rgb(30, 130, 150)
  ); /* ✅ لون أغمق عند التحويم */
}

.product-table {
  width: 100%;
  border-collapse: collapse;
}

.product-table th {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.product-table td {
  padding: 10px; /* إضافة مسافة داخلية */
  text-align: center;
}

.product-table th {
  background: linear-gradient(
    to top,
    rgb(25, 113, 132),
    rgb(38, 155, 180)
  ) !important;
  color: white; /* إذا كنت تريد النص واضحًا */
}

.price-column {
  text-align: right; /* محاذاة النص إلى اليمين */
  color: #000000; /* لون النص (اختياري) */
  font-weight: bold; /* جعل النص عريضاً (اختياري) */
}

.product-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.no-style {
  all: unset; /* إزالة جميع الأنماط الافتراضية */
  cursor: pointer; /* إبقاء المؤشر قابلاً للنقر */
  display: inline-flex; /* لضمان احتواء الأيقونة فقط */
  align-items: center; /* تمركز المحتوى عموديًا */
  justify-content: center; /* تمركز المحتوى أفقيًا */
}

.no-style:focus {
  outline: none; /* إزالة حدود التفاعل عند التركيز */
  box-shadow: none; /* إزالة أي تأثير ظل ناتج عن التركيز */
}

.no-style:hover {
  color: red; /* تغيير اللون عند التمرير (اختياري) */
  transform: scale(1.1); /* تكبير طفيف عند التمرير */
  transition: all 0.3s ease; /* تأثير سلس عند التغيير */
}

.icon-container {
  display: flex; /* ترتيب العناصر أفقيًا */
  justify-content: space-around;
  align-items: center; /* محاذاة الأيقونات عموديًا */
  gap: 10px; /* مسافة بين الأيقونات */
}

.icon-button {
  all: unset; /* إزالة أي أنماط افتراضية */
  cursor: pointer; /* مؤشر اليد */
  font-size: 20px; /* تكبير الأيقونة */
  color: #197184; /* لون افتراضي */
  transition: all 0.3s ease-in-out; /* تأثيرات سلسة */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* حجم موحد */
  height: 40px;
  border-radius: 50%; /* زر دائري */
}

.icon-button:hover {
  background: white; /* لون خلفية خفيف عند التمرير */
  box-shadow: 0px 4px 10px rgba(25, 113, 132, 0.4); /* تأثير طفو */
  transform: scale(1.15); /* تكبير طفيف */
  color: #25b6d2; /* لون فاتح عند التمرير */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-wrapper {
  position: relative;
  width: 70%; /* عرض حقل البحث */
  display: flex;
  align-items: center;
  border: 1px solid #ccc; /* الإطار الكامل */
  border-radius: 20px; /* الحواف المنحنية */
  background-color: #fffefe; /* لون رمادي فاتح */
  padding: 5px 15px; /* مسافات داخلية */
  flex: 0 1 70%; /* تحديد عرض البحث ليكون 70% */
}

.search-icon {
  font-size: 18px;
  color: #888; /* لون أيقونة المكبرة */
  margin-right: 10px; /* مسافة بين الأيقونة والنص */
}

.search-input {
  flex: 1; /* يسمح للحقل بالتمدد */
  border: none; /* إزالة أي حدود افتراضية */
  background: transparent; /* خلفية شفافة */
  font-size: 16px;
  border-bottom: none;
  color: #333; /* لون النص */
  padding: 5px; /* مسافة داخلية */
  outline: none; /* إزالة الإطار عند التركيز */
  box-shadow: none; /* إزالة أي ظلال (مثل الخط الأسود) */
  font-family: "Tajawal", sans-serif;
  font-size: 12px;
}

.search-input::placeholder {
  font-family: "Tajawal", sans-serif;
  font-size: 12px;
  color: #aaa; /* لون النص داخل الحقل */
}

.search-input:focus {
  outline: none; /* إزالة التحديد الافتراضي */
}

/* .add-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 20px; 
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

.add-button:hover {
  background-color: #7c7f7d; /* لون عند التمرير */
}

.table-container {
  max-height: 600px; /* ارتفاع ثابت للجدول */
  overflow-y: auto; /* تمكين سكرول بار عمودي */
  /* border: 1px solid #ccc; */

  margin-top: 0px;
}

.product-table {
  width: 100%; /* عرض الجدول بالكامل */
  border-collapse: collapse; /* إزالة المسافات بين الخلايا */
}

.product-table th {
  background-color: linear-gradient(to top, rgb(25 113 132), rgb(38 155 180));
  position: sticky; /* تثبيت الرأس عند التمرير */
  top: 0; /* يظل في الأعلى */
  z-index: 1; /* لجعل الرأس فوق البيانات */
}

.filter-dropdown,
td select {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f9f9f9;
  cursor: pointer;
}

.filter-dropdown:focus,
td select:focus {
  outline: none;
  border-color: #4caf50;
}
