/* ✅ **تنسيق الصفحة العامة** */
.product-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

/* ✅ **تنسيق شريط البحث وزر الرجوع** */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.back-button {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  padding: 8px;
  border-radius: 4px;
}

.search-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
}

/* ✅ **تنسيق شبكة المنتجات** */
.items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 16px;
}

/* ✅ **تصميم بطاقة المنتج** */
.item-card {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.item-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.item-details {
  margin-top: 12px;
  text-align: left;
}

.item-details h3 {
  font-size: 16px;
  margin-bottom: 8px;
}

.item-details p {
  font-size: 14px;
  margin-bottom: 4px;
}
