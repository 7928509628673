/* الحاوية الأساسية */
.home-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* عنوان الصفحة */
.home-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

/* شبكة الكروت */
.cards-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: start;
}
